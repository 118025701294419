import React from 'react';
import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';

const ROUND_ORDER = ['r32', 'r16', 'quarterfinals', 'semifinals', 'finals'];
const ROUND_TITLES = {
    r32: 'Round of 32',
    r16: 'Round of 16',
    quarterfinals: 'Quarterfinals',
    semifinals: 'Semifinals',
    finals: 'Finals'
};

const styles = StyleSheet.create({
    page: {
        padding: 40,
        backgroundColor: '#ffffff',
        flexDirection: 'row'
    },
    bracketContainer: {
        flexDirection: 'row',
        flex: 1,
        gap: 40
    },
    roundColumn: {
        width: 300,
        gap: 30
    },
    roundTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#1976d2'
    },
    match: {
        border: '1 solid #e0e0e0',
        borderRadius: 4,
        padding: 8,
        backgroundColor: '#f8f9fa',
        marginBottom: 20
    },
    matchNumber: {
        fontSize: 8,
        color: '#666666',
        marginBottom: 4
    },
    datetime: {
        fontSize: 8,
        color: '#666666',
        marginBottom: 4,
        backgroundColor: '#eceef0',
        padding: 4,
        borderRadius: 2
    },
    player: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 4,
        minHeight: 20
    },
    playerInfo: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4
    },
    playerName: {
        fontSize: 10
    },
    seed: {
        fontSize: 8,
        color: '#666666'
    },
    score: {
        fontSize: 10,
        color: '#666666'
    },
    winner: {
        fontWeight: 'bold',
        color: '#2e7d32'
    }
});

const getMatchReference = (roundIndex, matchIndex, matches, orderedRounds) => {
    let previousMatchesCount = 0;

    // Sum up matches from all previous rounds
    for (let i = 0; i < roundIndex; i += 1) {
        const roundKey = orderedRounds[i];
        previousMatchesCount += matches[roundKey].length;
    }

    // Add current match index + 1 to get the continuous match number
    return `Match ${previousMatchesCount + matchIndex + 1}`;
};

const getPlayerDisplay = (player, roundKey, matchIndex, prevRoundMatches, isFirstPlayer, roundIndex, matches, orderedRounds) => {
    if (player.name === 'TBD' && prevRoundMatches) {
        const prevMatchIndex = matchIndex * 2;
        const sourceMatchIndex = prevMatchIndex + (isFirstPlayer ? 0 : 1);

        if (sourceMatchIndex < prevRoundMatches.length) {
            // Calculate the match number from the previous round using our continuous counting function
            const prevRoundIndex = roundIndex - 1;
            const prevMatchNumber = getMatchReference(prevRoundIndex, sourceMatchIndex, matches, orderedRounds);
            return prevMatchNumber; // This will return "Match X" with the correct continuous number
        }
    }
    return player.name;
};

const renderPlayer = (player, isFirstPlayer, roundKey, matchIndex, prevRoundMatches, roundIndex, matches, orderedRounds) => (
    <View style={[styles.player]}>
        <View style={styles.playerInfo}>
            <Text style={[styles.playerName, player.winner && styles.winner]}>
                {getPlayerDisplay(player, roundKey, matchIndex, prevRoundMatches, isFirstPlayer, roundIndex, matches, orderedRounds)}
            </Text>
            {player.seed && (
                <Text style={styles.seed}>[{player.seed}]</Text>
            )}
        </View>
        <Text style={styles.score}>
            {player.score?.join(' ') || ''}
            {player.walkover && 'W/O'}
        </Text>
    </View>
);

const renderMatch = (match, roundKey, matchIndex, prevRoundMatches, roundIndex, matches, orderedRounds) => (
    <View style={styles.match}>
        <Text style={styles.matchNumber}>
            {getMatchReference(roundIndex, matchIndex, matches, orderedRounds)}
        </Text>
        <Text style={styles.datetime}>
            {match.datetime
                ? dayjs(match.datetime).format('MMM D, h:mm A')
                : 'Time TBD'}
            {match.location ? ` - ${match.location}` : ''}
        </Text>
        {renderPlayer(match.player1, true, roundKey, matchIndex, prevRoundMatches, roundIndex, matches, orderedRounds)}
        {renderPlayer(match.player2, false, roundKey, matchIndex, prevRoundMatches, roundIndex, matches, orderedRounds)}
    </View>
);

const BracketPDF = ({ matches, category, stage }) => {
    const orderedRounds = ROUND_ORDER.filter(round => matches[round]?.length > 0);

    return (
        <Document>
            <Page size="A3" orientation="landscape" style={styles.page}>
                <View style={styles.bracketContainer}>
                    {orderedRounds.map((roundKey, roundIndex) => {
                        const prevRoundKey = roundIndex > 0 ? orderedRounds[roundIndex - 1] : null;
                        const prevRoundMatches = prevRoundKey ? matches[prevRoundKey] : null;

                        return (
                            <View key={roundKey} style={styles.roundColumn}>
                                <Text style={styles.roundTitle}>
                                    {ROUND_TITLES[roundKey]}
                                </Text>
                                {matches[roundKey].map((match, matchIndex) => (
                                    <View key={matchIndex}>
                                        {renderMatch(
                                            match,
                                            roundKey,
                                            matchIndex,
                                            prevRoundMatches,
                                            roundIndex,
                                            matches,
                                            orderedRounds
                                        )}
                                    </View>
                                ))}
                            </View>
                        );
                    })}
                </View>
            </Page>
        </Document>
    );
};

export const DownloadBracketPDF = ({ matches, category, stage }) => {
    return (
        <PDFDownloadLink
            document={<BracketPDF matches={matches} category={category} stage={stage} />}
            fileName={`${category}-${stage}-bracket.pdf`}
        >
            {({ blob, url, loading, error }) => (
                <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    disabled={loading}
                    sx={{ ml: 2 }}
                >
                    {loading ? 'Generating PDF...' : 'Download PDF'}
                </Button>
            )}
        </PDFDownloadLink>
    );
};