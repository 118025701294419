import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Select,
    MenuItem,
    CircularProgress,
    InputLabel,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    Button,
    Tabs,
    Tab,
    TextField,
    FormControlLabel
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import useResponsive from '../hooks/useResponsive';
import { updateTournamentByTournamentId } from '../api/ApiGateway';
import { DownloadBracketPDF } from './BracketPdf';

// Styled components for scores
const ScoreContainer = styled('div')({
    display: 'inline-flex',
    alignItems: 'flex-start',
    position: 'relative'
});

const ScoreNumber = styled('span')({
    minWidth: '16px',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: '1.2'
});

const TieBreakScore = styled('sup')({
    fontSize: '0.7rem',
    lineHeight: '1',
    marginLeft: '1px',
    color: '#666',
    position: 'relative',
    top: '-0.2em'
});

// Main bracket container
const BracketContainer = styled('div')({
    display: 'flex',
    padding: '20px',
    gap: '40px',
    overflowX: 'auto',
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 1
});

// Round switcher for mobile
const RoundSwitcher = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '16px'
}));

const RoundButton = styled(Button)(({ selected }) => ({
    minWidth: '48px',
    height: '48px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: selected ? '#1976d2' : '#fff',
    color: selected ? '#fff' : '#000',
    border: '1px solid #e0e0e0',
    '&:hover': {
        backgroundColor: selected ? '#1565c0' : '#f5f5f5'
    }
}));

// Round column
const Round = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    minWidth: '300px',
    position: 'relative',
    '& h2': {
        margin: '0 0 16px 0',
        fontSize: '16px',
        fontWeight: 500
    }
});

const RoundTitle = styled(Typography)({
    fontSize: '24px',
    fontWeight: 500,
    marginBottom: '16px',
    paddingBottom: '8px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    '&.active': {
        color: '#1976d2',
        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: -1,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#1976d2'
        }
    }
});

// Match card
const Match = styled(Card)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
    borderRadius: '4px'
}));

const MatchContent = styled(CardContent)({
    padding: '8px !important'
});

// Player styles
const Player = styled('div')(({ winner }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    position: 'relative',
    '& > span:first-of-type': {
        fontWeight: winner ? 500 : 400
    }
}));

// Player separator line - we'll reference this element specifically
const PlayerSeparator = styled('div')({
    height: '1px',
    width: '100%',
    backgroundColor: '#e0e0e0',
    margin: '0',
    position: 'relative' // Add position for reference
});

const Score = styled('div')({
    display: 'flex',
    gap: '4px',
    alignItems: 'center'
});

const Seed = styled('span')({
    color: '#666',
    fontSize: '0.9em',
    marginLeft: '4px'
});

const DateTime = styled('div')({
    fontSize: '0.85em',
    color: '#666',
    marginBottom: '8px',
    backgroundColor: '#eceef0',
    padding: '8px',
    borderRadius: '4px',
    minHeight: '35px'
});

const Location = styled('span')({
    fontSize: '0.85em',
    color: '#666',
    float: 'right'
});

const WinnerCheck = styled(CheckIcon)({
    color: '#2e7d32',
    fontSize: '16px',
    marginRight: '4px'
});

// Schedule dialog component
const ScheduleDialog = ({ open, onClose, onSave, currentDateTime, currentVenue }) => {
    const [venue, setVenue] = React.useState(currentVenue?.replace('Court ', '') || '');
    const [dateTime, setDateTime] = React.useState(currentDateTime ? dayjs(currentDateTime) : null);

    const handleSave = () => {
        onSave({
            venue: venue ? `Court ${venue}` : null,
            dateTime: dateTime ? dateTime.format('YYYY-MM-DD HH:mm') : null
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Schedule Match</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Date & Time"
                            value={dateTime}
                            onChange={setDateTime}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <Select
                        value={venue}
                        onChange={(e) => setVenue(e.target.value)}
                        label="Court"
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map(court => (
                            <MenuItem key={court} value={court}>Court {court}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

// Result dialog component
const ResultDialog = ({ open, onClose, onSave, match }) => {
    const [player1Score, setPlayer1Score] = React.useState('');
    const [player2Score, setPlayer2Score] = React.useState('');
    const [player1TieBreak, setPlayer1TieBreak] = React.useState('');
    const [player2TieBreak, setPlayer2TieBreak] = React.useState('');
    const [winner, setWinner] = React.useState('');
    const [resultType, setResultType] = React.useState('normal');

    React.useEffect(() => {
        if (match) {
            // Initialize scores from match data
            setPlayer1Score(match.player1?.score?.[0] || '');
            setPlayer2Score(match.player2?.score?.[0] || '');
            setPlayer1TieBreak(match.player1?.tieBreak?.[0] || '');
            setPlayer2TieBreak(match.player2?.tieBreak?.[0] || '');
            setWinner('');
            setResultType('normal');

            if (match.player1.name === 'BYE' || match.player2.name === 'BYE') {
                setResultType('bye');
                setWinner(match.player1.name === 'BYE' ? 'player2' : 'player1');
            }
        }
    }, [match]);

    if (!match) return null;

    const handleSave = () => {
        const result = {
            player1: {
                ...match.player1,
                score: resultType === 'normal' ? [parseInt(player1Score, 10)] : [],
                tieBreak: resultType === 'normal' && player1TieBreak ? [parseInt(player1TieBreak, 10)] : [],
                winner: winner === 'player1',
                walkover: resultType === 'walkover' && winner === 'player2',
                bye: resultType === 'bye' && match.player1.name === 'BYE'
            },
            player2: {
                ...match.player2,
                score: resultType === 'normal' ? [parseInt(player2Score, 10)] : [],
                tieBreak: resultType === 'normal' && player2TieBreak ? [parseInt(player2TieBreak, 10)] : [],
                winner: winner === 'player2',
                walkover: resultType === 'walkover' && winner === 'player1',
                bye: resultType === 'bye' && match.player2.name === 'BYE'
            }
        };
        onSave(result);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '400px',
                    minHeight: '400px',
                    margin: '16px'
                }
            }}
        >
            <DialogTitle>Enter Match Result</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '280px',
                    p: { xs: 2, sm: 3 },
                    '& .MuiFormControl-root': {
                        width: '100%'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        flex: 1
                    }}
                >
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Result Type</FormLabel>
                        <RadioGroup
                            value={resultType}
                            onChange={(e) => setResultType(e.target.value)}
                            sx={{ mb: 2 }}
                        >
                            <FormControlLabel
                                value="normal"
                                control={<Radio />}
                                label="Normal Match"
                                disabled={match.player1.name === 'BYE' || match.player2.name === 'BYE'}
                            />
                            <FormControlLabel
                                value="walkover"
                                control={<Radio />}
                                label="Walkover"
                                disabled={match.player1.name === 'BYE' || match.player2.name === 'BYE'}
                            />
                            <FormControlLabel
                                value="bye"
                                control={<Radio />}
                                label="BYE"
                                disabled={match.player1.name !== 'BYE' && match.player2.name !== 'BYE'}
                            />
                        </RadioGroup>
                    </FormControl>

                    {resultType === 'normal' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {/* Score input section */}
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                <TextField
                                    label={`${match.player1.name}'s Score`}
                                    value={player1Score}
                                    onChange={(e) => {
                                        const newScore = e.target.value;
                                        setPlayer1Score(newScore);
                                        if (Math.abs(parseInt(newScore, 10) - parseInt(player2Score, 10)) !== 1) {
                                            setPlayer1TieBreak('');
                                            setPlayer2TieBreak('');
                                        }
                                    }}
                                    type="number"
                                    fullWidth
                                    size="medium"
                                />
                                {player1Score && player2Score &&
                                    Math.abs(parseInt(player1Score, 10) - parseInt(player2Score, 10)) === 1 && (
                                        <TextField
                                            label="Tie Break"
                                            value={player1TieBreak}
                                            onChange={(e) => setPlayer1TieBreak(e.target.value)}
                                            type="number"
                                            size="medium"
                                            sx={{ width: '120px' }}
                                        />
                                    )}
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                <TextField
                                    label={`${match.player2.name}'s Score`}
                                    value={player2Score}
                                    onChange={(e) => {
                                        const newScore = e.target.value;
                                        setPlayer2Score(newScore);
                                        if (Math.abs(parseInt(player1Score, 10) - parseInt(newScore, 10)) !== 1) {
                                            setPlayer1TieBreak('');
                                            setPlayer2TieBreak('');
                                        }
                                    }}
                                    type="number"
                                    fullWidth
                                    size="medium"
                                />
                                {player1Score && player2Score &&
                                    Math.abs(parseInt(player1Score, 10) - parseInt(player2Score, 10)) === 1 && (
                                        <TextField
                                            label="Tie Break"
                                            value={player2TieBreak}
                                            onChange={(e) => setPlayer2TieBreak(e.target.value)}
                                            type="number"
                                            size="medium"
                                            sx={{ width: '120px' }}
                                        />
                                    )}
                            </Box>
                        </Box>
                    )}

                    <FormControl fullWidth>
                        <InputLabel>Winner</InputLabel>
                        <Select
                            value={winner}
                            onChange={(e) => setWinner(e.target.value)}
                            label="Winner"
                            disabled={resultType === 'bye'}
                            sx={{ mt: 1 }}
                        >
                            <MenuItem value="player1">{match.player1.name}</MenuItem>
                            <MenuItem value="player2">{match.player2.name}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

// Precisely calculated spacing formula based on the exact component measurements
const calculateMatchOffset = (roundIndex, matchIndex, totalMatches) => {
    const MATCH_HEIGHT = 140; // Height of a single match card
    const MATCH_SPACING = 30; // Base spacing between matches in the same round

    if (roundIndex === 0) return 0;

    // For semifinals (round 1)
    if (roundIndex === 1) {
        if (matchIndex === 0) {
            return MATCH_HEIGHT / 2
        }
        // Calculate the midpoint between two consecutive quarterfinal matches
        return MATCH_HEIGHT + (MATCH_SPACING);
    }

    // For finals (round 2)
    if (roundIndex === 2) {
        if (matchIndex === 0) {
            return (MATCH_HEIGHT) * 1.5 + (MATCH_SPACING) * 1;
        }
        // Calculate the midpoint between two semifinal matches
        return (MATCH_HEIGHT) * 3 + (MATCH_SPACING) * 3;
    }

    return 0;
};

// Round configuration
const ROUND_CONFIG = [
    { key: 'r64', label: 'R64', mobileLabel: 'R64', title: 'Round of 64' },
    { key: 'r32', label: 'R32', mobileLabel: 'R32', title: 'Round of 32' },
    { key: 'r16', label: 'R16', mobileLabel: 'R16', title: 'Round of 16' },
    { key: 'quarterfinals', label: 'Quarterfinals', mobileLabel: 'QF', title: 'Quarterfinals' },
    { key: 'semifinals', label: 'Semifinals', mobileLabel: 'SF', title: 'Semifinals' },
    { key: 'finals', label: 'Finals', mobileLabel: 'F', title: 'Finals' }
];

export const TennisBracket = ({
    matches = {},
    tournamentId,
    isAdmin
}) => {
    console.log('tournament id', tournamentId);

    // State
    const [localMatches, setLocalMatches] = useState(matches);
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const [resultDialogOpen, setResultDialogOpen] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [selectedMatchInfo, setSelectedMatchInfo] = useState(null);
    const [loadingMatch, setLoadingMatch] = useState(null);

    // Container and separator refs for measuring positions
    const bracketContainerRef = useRef(null);
    const matchRefs = useRef({});
    const separatorRefs = useRef({});

    // Track player separator positions for SVG connectors
    const [separatorPositions, setSeparatorPositions] = useState({});

    const categories = Object.keys(localMatches);
    const [selectedCategory, setSelectedCategory] = useState(categories[0] || '');

    const availableStages = React.useMemo(() =>
        selectedCategory ? Object.keys(localMatches[selectedCategory]) : [],
        [localMatches, selectedCategory]);

    const [selectedStage, setSelectedStage] = useState(availableStages[0] || '');

    // Get current matches based on selected category and stage
    const currentMatches = React.useMemo(() =>
        selectedCategory && selectedStage ? localMatches[selectedCategory][selectedStage] : {},
        [localMatches, selectedCategory, selectedStage]);

    // Get available rounds based on current matches
    const availableRounds = React.useMemo(() =>
        ROUND_CONFIG.filter(round =>
            currentMatches[round.key] && currentMatches[round.key].length > 0
        ),
        [currentMatches]);

    const [visibleRounds, setVisibleRounds] = useState(() =>
        availableRounds.slice(0, 3).map(round => round.key)
    );

    const [selectedRound, setSelectedRound] = useState(() =>
        availableRounds[1]?.label || availableRounds[0]?.label
    );

    const isDesktop = useResponsive('up', 'lg');

    // Generate keys for refs
    const getMatchRefKey = (round, matchIndex) => `match-${round}-${matchIndex}`;
    const getSeparatorRefKey = (round, matchIndex) => `separator-${round}-${matchIndex}`;

    // Store match and separator refs
    const setMatchRef = (round, matchIndex, element) => {
        const key = getMatchRefKey(round, matchIndex);
        matchRefs.current[key] = element;
    };

    const setSeparatorRef = (round, matchIndex, element) => {
        const key = getSeparatorRefKey(round, matchIndex);
        separatorRefs.current[key] = element;
    };

    // Update positions for connectors
    useEffect(() => {
        if (!isDesktop || !bracketContainerRef.current) return;

        const bracketRect = bracketContainerRef.current.getBoundingClientRect();
        const positions = {};

        // Calculate positions for all separators
        Object.entries(separatorRefs.current).forEach(([key, element]) => {
            if (element) {
                const rect = element.getBoundingClientRect();

                positions[key] = {
                    left: rect.left - bracketRect.left,
                    right: rect.right - bracketRect.left,
                    top: rect.top - bracketRect.top + (rect.height / 2), // Center of the separator
                    width: rect.width,
                    height: rect.height
                };
            }
        });

        setSeparatorPositions(positions);
    }, [visibleRounds, selectedCategory, selectedStage, isDesktop]);

    // Generate SVG connector paths with strictly horizontal/vertical lines (no diagonals)
    const generateConnectorPaths = () => {
        if (!isDesktop) return null;

        const displayRounds = getDisplayRounds();
        const paths = [];

        // Generate connector paths between rounds
        for (let i = 0; i < displayRounds.length - 1; i += 1) {
            const currentRound = displayRounds[i];
            const nextRound = displayRounds[i + 1];

            // Process match pairs
            for (let j = 0; j < currentRound.matches.length; j += 2) {
                // Only process if we have a complete pair and a corresponding next match
                if (j + 1 < currentRound.matches.length) {
                    const nextMatchIndex = Math.floor(j / 2);

                    // Only draw line if there's a corresponding match in the next round
                    if (nextRound.matches[nextMatchIndex]) {
                        const separator1Key = getSeparatorRefKey(currentRound.key, j);
                        const separator2Key = getSeparatorRefKey(currentRound.key, j + 1);
                        const nextSeparatorKey = getSeparatorRefKey(nextRound.key, nextMatchIndex);

                        const separator1 = separatorPositions[separator1Key];
                        const separator2 = separatorPositions[separator2Key];
                        const nextSeparator = separatorPositions[nextSeparatorKey];

                        if (separator1 && separator2 && nextSeparator) {
                            // Calculate midpoint between the two matches
                            const midX = separator1.right + 20;
                            const midY = (separator1.top + separator2.top) / 2;

                            // Draw horizontal line from first separator to vertical connector
                            paths.push(
                                <path
                                    key={`h1-${separator1Key}`}
                                    d={`M ${separator1.right} ${separator1.top} L ${midX} ${separator1.top}`}
                                    stroke="#e0e0e0"
                                    strokeWidth="1.5"
                                    fill="none"
                                />
                            );

                            // Draw horizontal line from second separator to vertical connector
                            paths.push(
                                <path
                                    key={`h2-${separator2Key}`}
                                    d={`M ${separator2.right} ${separator2.top} L ${midX} ${separator2.top}`}
                                    stroke="#e0e0e0"
                                    strokeWidth="1.5"
                                    fill="none"
                                />
                            );

                            // Draw vertical line connecting the horizontal lines
                            paths.push(
                                <path
                                    key={`v-${separator1Key}-${separator2Key}`}
                                    d={`M ${midX} ${separator1.top} L ${midX} ${separator2.top}`}
                                    stroke="#e0e0e0"
                                    strokeWidth="1.5"
                                    fill="none"
                                />
                            );

                            // Draw horizontal connector to next round (using right angles)
                            // First horizontal line from vertical connector
                            paths.push(
                                <path
                                    key={`h3-${nextSeparatorKey}`}
                                    d={`M ${midX} ${midY} L ${nextSeparator.left} ${midY}`}
                                    stroke="#e0e0e0"
                                    strokeWidth="1.5"
                                    fill="none"
                                />
                            );

                            // Vertical line from horizontal connector to the next separator
                            paths.push(
                                <path
                                    key={`v2-${nextSeparatorKey}`}
                                    d={`M ${nextSeparator.left} ${midY} L ${nextSeparator.left} ${nextSeparator.top}`}
                                    stroke="#e0e0e0"
                                    strokeWidth="1.5"
                                    fill="none"
                                />
                            );
                        }
                    }
                }
            }
        }

        console.log("Generated connector paths:", paths.length);
        return paths;
    };

    // Handle updates to match data
    const handleLocalUpdate = async (category, stage, round, matchIndex, updatedMatch) => {
        // First update local state for immediate UI feedback
        setLoadingMatch({ round, matchIndex });
        setLocalMatches(prevMatches => {
            const newMatches = JSON.parse(JSON.stringify(prevMatches));
            newMatches[category][stage][round][matchIndex] = updatedMatch;
            return newMatches;
        });

        try {
            // Prepare the data for the API call
            const newMatches = JSON.parse(JSON.stringify(localMatches));
            newMatches[category][stage][round][matchIndex] = updatedMatch;
            const updateData = {
                id: tournamentId,
                attributes: {
                    matches: newMatches
                }
            };

            // Make the API call
            await updateTournamentByTournamentId(updateData);
        } catch (error) {
            console.error('Failed to update tournament:', error);

            // Revert the local state if the API call fails
            setLocalMatches(prevMatches => {
                const newMatches = JSON.parse(JSON.stringify(prevMatches));
                newMatches[category][stage][round][matchIndex] = matches[category][stage][round][matchIndex];
                return newMatches;
            });
        }
        finally {
            // Clear loading state
            setLoadingMatch(null);
        }
    };

    if (!categories.length) {
        return <Typography>No tournament data available</Typography>;
    }

    if (!availableStages.length) {
        return <Typography>No stages available for selected category</Typography>;
    }

    if (!availableRounds.length) {
        return <Typography>No rounds available for selected stage</Typography>;
    }

    // Handle round change
    const handleRoundChange = (_, newRound) => {
        const roundIndex = availableRounds.findIndex(r => r.label === newRound);
        if (roundIndex === -1) return;

        let newVisibleRounds;
        if (roundIndex === 0) {
            newVisibleRounds = availableRounds.slice(0, 3).map(r => r.key);
        } else if (roundIndex === availableRounds.length - 1) {
            newVisibleRounds = availableRounds.slice(-3).map(r => r.key);
        } else {
            newVisibleRounds = availableRounds.slice(roundIndex - 1, roundIndex + 2).map(r => r.key);
        }

        setVisibleRounds(newVisibleRounds);
        setSelectedRound(newRound);
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        // Reset stage to first available stage in new category
        const newStages = Object.keys(matches[newCategory]);
        setSelectedStage(newStages[0]);
        // Reset rounds based on new matches
        const newMatches = matches[newCategory][newStages[0]];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    // Handle stage change
    const handleStageChange = (event) => {
        const newStage = event.target.value;
        setSelectedStage(newStage);
        // Reset rounds based on new matches
        const newMatches = matches[selectedCategory][newStage];
        const newAvailableRounds = ROUND_CONFIG.filter(round =>
            newMatches[round.key] && newMatches[round.key].length > 0
        );
        setVisibleRounds(newAvailableRounds.slice(0, 3).map(round => round.key));
        setSelectedRound(newAvailableRounds[1]?.label || newAvailableRounds[0]?.label);
    };

    // Handle schedule updates
    const handleScheduleUpdate = (round, matchIndex, updates) => {
        if (!currentMatches || !round || matchIndex === undefined) {
            console.error('Missing required data for update');
            return;
        }

        const currentMatch = currentMatches[round]?.[matchIndex];
        if (!currentMatch) {
            console.error('Match not found');
            return;
        }

        const updatedMatch = {
            ...currentMatch,
            datetime: updates.dateTime,
            location: updates.venue
        };

        // Use the correct round key from selectedMatch
        handleLocalUpdate(
            selectedCategory,
            selectedStage,
            round,
            matchIndex,
            updatedMatch
        );

        setScheduleDialogOpen(false);
    };

    const handleResultSubmit = (round, matchIndex, result) => {
        // Find the index of the current round in our configuration
        const roundIndex = ROUND_CONFIG.findIndex(r => r.key === round);
        const nextRound = ROUND_CONFIG[roundIndex + 1]?.key;
        const nextMatchIndex = Math.floor(matchIndex / 2);

        // Create a deep copy of the current matches
        const newMatches = JSON.parse(JSON.stringify(localMatches));

        // Update the current match with the result
        const updatedMatch = {
            ...currentMatches[round][matchIndex],
            ...result
        };

        // Update the current match in our copy
        newMatches[selectedCategory][selectedStage][round][matchIndex] = updatedMatch;

        // If there's a next round, update the next match as well
        if (nextRound && currentMatches[nextRound]) {
            const winner = result.player1.winner ? result.player1 : result.player2;

            // Get the existing next round match (or create a new one if it doesn't exist)
            const nextMatch = currentMatches[nextRound][nextMatchIndex] || {
                player1: { name: 'TBD' },
                player2: { name: 'TBD' }
            };

            // Determine if this winner should go to player1 or player2 slot
            const isEvenMatch = matchIndex % 2 === 0;

            const updatedNextMatch = {
                ...nextMatch,
                [isEvenMatch ? 'player1' : 'player2']: {
                    name: winner.name,
                    seed: winner.seed
                }
            };

            // Update the next match in our copy
            newMatches[selectedCategory][selectedStage][nextRound][nextMatchIndex] = updatedNextMatch;
        }

        // Make a single API call with both updates
        setLoadingMatch({ round, matchIndex });

        const updateData = {
            id: tournamentId,
            attributes: {
                matches: newMatches
            }
        };

        // Update local state and make API call
        setLocalMatches(newMatches);

        updateTournamentByTournamentId(updateData)
            .catch(error => {
                console.error('Failed to update tournament:', error);
                // Revert to previous state if the API call fails
                setLocalMatches(localMatches);
            })
            .finally(() => {
                setLoadingMatch(null);
            });

        setResultDialogOpen(false);
    };

    const renderPlayer = (player) => (
        <Player winner={player.winner}>
            <span>
                {player.name}
                {player.seed && <Seed>[{player.seed}]</Seed>}
            </span>
            <Score>
                {player.winner && <WinnerCheck />}
                {player.score?.map((score, index) => (
                    <ScoreContainer key={index}>
                        <ScoreNumber>{score}</ScoreNumber>
                        {player.tieBreak?.[index] && (
                            <TieBreakScore>{player.tieBreak[index]}</TieBreakScore>
                        )}
                    </ScoreContainer>
                ))}
                {player.walkover && <span>W/O</span>}
            </Score>
        </Player>
    );

    // Render a match with player separator reference
    const renderMatch = (match, round, matchIndex) => {
        const isLoading = loadingMatch &&
            loadingMatch.round === round &&
            loadingMatch.matchIndex === matchIndex;

        return (
            <div ref={el => setMatchRef(round, matchIndex, el)}>
                <Match elevation={1}>
                    <MatchContent>
                        {isLoading && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    zIndex: 10
                                }}
                            >
                                <CircularProgress size={24} />
                            </Box>
                        )}
                        <DateTime
                            onClick={(e) => {
                                e.stopPropagation();
                                if (isAdmin && match && match.player1?.name !== 'BYE' && match.player2?.name !== 'BYE') {
                                    setSelectedMatch({ round, matchIndex });
                                    setSelectedMatchInfo(match);
                                    setScheduleDialogOpen(true);
                                }
                            }}
                            style={{ cursor: isAdmin && match.player1?.name !== 'BYE' && match.player2?.name !== 'BYE' ? 'pointer' : 'default' }}
                        >
                            {match.player1?.name !== 'BYE' && match.player2?.name !== 'BYE' && (
                                <>
                                    {match.datetime ? dayjs(match.datetime).format('ddd, MMM D, h:mm A') : 'Time TBD'}
                                    <Location>{match.location || 'Court TBD'}</Location>
                                </>
                            )}
                        </DateTime>
                        <Box
                            onClick={() => {
                                if (isAdmin && match) {
                                    setSelectedMatch({ round, matchIndex });
                                    setSelectedMatchInfo(match);
                                    setResultDialogOpen(true);
                                }
                            }}
                            style={{ cursor: isAdmin ? 'pointer' : 'default' }}
                        >
                            {renderPlayer(match.player1)}

                            {/* Player separator with ref for connector lines */}
                            <PlayerSeparator ref={el => setSeparatorRef(round, matchIndex, el)} />

                            {renderPlayer(match.player2)}
                        </Box>
                    </MatchContent>
                </Match>
            </div>
        );
    };

    // Get the rounds that should be displayed
    const getDisplayRounds = () => {
        return visibleRounds.map(roundKey => ({
            key: roundKey,
            title: ROUND_CONFIG.find(r => r.key === roundKey)?.title,
            matches: currentMatches[roundKey]
        }));
    };

    // Main bracket with SVG connectors
    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                {isDesktop ? (
                    <Tabs
                        value={selectedRound}
                        onChange={handleRoundChange}
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#1976d2'
                            }
                        }}
                    >
                        {availableRounds.map(({ label, key }) => (
                            <Tab
                                key={label}
                                label={label}
                                value={label}
                                sx={{
                                    color: visibleRounds.includes(key)
                                        ? '#1976d2'
                                        : 'text.primary',
                                    '&.Mui-selected': {
                                        color: '#1976d2'
                                    }
                                }}
                            />
                        ))}
                    </Tabs>
                ) : null}

                <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    size="small"
                >
                    {categories.map(category => (
                        <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                </Select>

                <Select
                    value={selectedStage}
                    onChange={handleStageChange}
                    size="small"
                >
                    {availableStages.map(stage => (
                        <MenuItem key={stage} value={stage}>{stage}</MenuItem>
                    ))}
                </Select>

                {/* <DownloadBracketPDF
                    matches={currentMatches}
                    category={selectedCategory}
                    stage={selectedStage}
                /> */}
            </Box>

            {/* Mobile round switcher */}
            {!isDesktop && (
                <RoundSwitcher>
                    {availableRounds.map(({ label, mobileLabel }) => (
                        <RoundButton
                            key={label}
                            selected={selectedRound === label}
                            onClick={() => handleRoundChange(null, label)}
                            variant="outlined"
                        >
                            {mobileLabel}
                        </RoundButton>
                    ))}
                </RoundSwitcher>
            )}

            {/* Main bracket with SVG connectors - position:relative on outer container is critical */}
            <Box sx={{ position: 'relative', width: '100%', overflow: 'auto', border: '1px solid transparent' }}>
                <div ref={bracketContainerRef} style={{ position: 'relative' }}>
                    {/* The SVG overlay must be after BracketContainer in DOM order for z-index to work properly */}
                    <BracketContainer>
                        {(isDesktop ? getDisplayRounds() : [
                            {
                                key: ROUND_CONFIG.find(r => r.label === selectedRound)?.key,
                                title: ROUND_CONFIG.find(r => r.label === selectedRound)?.title,
                                matches: currentMatches[ROUND_CONFIG.find(r => r.label === selectedRound)?.key]
                            }
                        ]).map((round, roundIndex) => (
                            <Round key={round.title} sx={{ minWidth: isDesktop ? '300px' : '100%' }}>
                                <RoundTitle
                                    variant="h2"
                                    className={isDesktop ?
                                        (round.title === ROUND_CONFIG.find(r => r.label === selectedRound)?.title ? 'active' : '')
                                        : 'active'
                                    }
                                >
                                    {round.title}
                                </RoundTitle>
                                {round.matches?.map((match, matchIndex) => (
                                    <div
                                        key={matchIndex}
                                        style={{
                                            position: 'relative',
                                            marginTop: isDesktop ?
                                                calculateMatchOffset(roundIndex, matchIndex, round.matches.length)
                                                : (matchIndex === 0 ? 0 : 30)
                                        }}
                                    >
                                        {renderMatch(match, round.key, matchIndex)}
                                    </div>
                                ))}
                            </Round>
                        ))}
                    </BracketContainer>

                    {/* SVG overlay for right-angle connector lines - must be on top */}
                    {/* {isDesktop && Object.keys(separatorPositions).length > 0 && (
                        <svg
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none',
                                zIndex: 1000,
                                border: '1px solid transparent'
                            }}
                        >
                            {generateConnectorPaths()}
                        </svg>
                    )} */}
                </div>
            </Box>

            {selectedMatchInfo && (
                <>
                    <ScheduleDialog
                        open={scheduleDialogOpen}
                        onClose={() => {
                            setScheduleDialogOpen(false);
                            setSelectedMatchInfo(null);
                        }}
                        onSave={(updates) => {
                            handleScheduleUpdate(
                                selectedMatch.round,
                                selectedMatch.matchIndex,
                                updates
                            );
                        }}
                        currentDateTime={selectedMatchInfo.datetime}
                        currentVenue={selectedMatchInfo.location}
                    />

                    <ResultDialog
                        open={resultDialogOpen}
                        onClose={() => {
                            setResultDialogOpen(false);
                            setSelectedMatchInfo(null);
                        }}
                        onSave={(result) => {
                            handleResultSubmit(
                                selectedMatch.round,
                                selectedMatch.matchIndex,
                                result
                            );
                        }}
                        match={selectedMatchInfo}
                    />
                </>
            )}
        </>
    );
};